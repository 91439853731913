<template>
	<div class="wrap">
		<el-page-header @back="$router.back()" content="编辑客户档案" class="margin-bottom margin-top-xs"></el-page-header>
		<div>
			<el-divider content-position="left">基本信息</el-divider>
			<div class="form_box">
				<el-form ref="form" :model="form" label-width="100px" class="flex flex-wrap" v-loading="loading">
					<el-form-item label="日期" prop="created_at"  :rules="rules.required">
						<div class="form-item-box">
							<el-date-picker v-model="form.created_at" type="date" placeholder="选择日期"
								format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" style="width: 100%;">
							</el-date-picker>
						</div>
					</el-form-item>
					<el-form-item label="系列" prop="series"  :rules="rules.required">
						<div class="form-item-box">
							<el-input v-model="form.series" placeholder="请输入系列"></el-input>
						</div>
					</el-form-item>
					
					<el-form-item label="编号"  prop="no"  :rules="rules.required">
						<div class="form-item-box">
							<el-input v-model="form.no" placeholder="请输入编号"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="产品名称" prop="name"  :rules="rules.required">
						<div class="form-item-box" >
							<el-input v-model="form.name" placeholder="请输入产品名称"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="容量"  prop="capacity"  :rules="rules.required">
						<div class="form-item-box">
							<el-input v-model="form.capacity" placeholder="请输入容量"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="数量"  prop="num"  :rules="rules.required">
						<div class="form-item-box">
							<el-input v-model="form.num" placeholder="请输入数量"></el-input>
						</div>
					</el-form-item>

					<el-form-item label="领用人"  prop="autograph"   :rules="rules.required">
						<div class="form-item-box">
							<el-input v-model="form.autograph" placeholder="请输入领用人"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="领用人确认" prop="is_confirm"   :rules="rules.required">
						<div class="form-item-box">
							<el-radio v-model="form.is_confirm" :label="1">是</el-radio>
							<el-radio v-model="form.is_confirm" :label="0">否</el-radio>
						</div>
					</el-form-item>
					<el-form-item label="备注" style="width: 100%;">
						<div class="form-item-box" style="width: 100%;">
							<el-input type="textarea" :rows="4" v-model="form.remarks"></el-input>
						</div>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit()">保存</el-button>
						<el-button @click="$router.back()">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
			
		</div>
	</div>
</template>


<script>
	// 引入公用表单验证
	import rules from "../../../common/common.rules";
	// 行政区划npm版本
	// import ChinaLocation from "china-location";
	// import list from "china-location/dist/location.json";
	export default {
		data() {
			return {
				loading: false,
				rules,
				form: {
					id: this.$route.params.id, // int 服务ID
					series: "", 
					name:"",
					num: "", 
					autograph: "", 
					remarks: '', 
					created_at:'',
					is_confirm:''
				},
				
			};
		},
		
		mounted() {
			if (this.form.id) {
				this.getInfo()
			}
		},
		methods: {
			//获取详情
			getInfo() {
				this.$api.table.collectDetail({
					id: this.form.id
				}).then(res => {
					console.log(res)
					this.form=res.data
				})
			},
			
			onSubmit() {
				console.log(this.form)
				this.$refs.form.validate(valid => {
					if (valid) {
						const loading = this.$loading();
						this.$api.table.Addcollect(this.form).then(res => {
								loading.close();
								if (res.code == 200) {
									// 保存成功
									this.$message.success(`保存成功`);
									this.$router.back();
								} else {
									this.$message.error(res.msg);
								}
							})
							.catch(err => {
								loading.close();
								this.$alert(err);
							});
					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});
			}
		}
	};
</script>
<style scoped lang="scss">
	.form_box {
		max-width: 800px;
	}

	.form-item-box {
		width: 300px
	}
</style>
